import React, { useState } from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Link from './link';
import Loadable from 'react-loadable';
import { ExternalLink } from 'react-feather';
import config from '../../config';
import LoadingProvider from './mdxComponents/loading';
import { DarkModeSwitch } from './DarkModeSwitch';
import Sidebar from './sidebar';
import logoImg from './images/mimik_logo.png'
import logoImgBw from './images/mimik_bw.svg'
import { Search, Close } from '@styled-icons/fa-solid';
import { Menu } from '@styled-icons/boxicons-regular/Menu';
import PackagesJson from '../../package.json';

const help = require('./images/help.svg');

const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];

if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`,
  });
}

const ListItem = styled(({ className, active, level, ...props }) => {
  return (
    <li className={className}>
      <a href={props.to} {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    </li>
  );
})`
  list-style: none;

  a {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    font-weight: ${({ level }) => (level === 0 ? 700 : 400)};
    padding: 0.45rem 0 0.45rem ${props => 2 + (props.level || 0) * 1}rem;
    display: block;
    position: relative;
 
    ${props =>
    props.active &&
    `
      // color: #663399;
      border-color: rgb(230,236,241) !important;
      border-style: solid none solid solid;
      border-width: 1px 0px 1px 1px;
      background-color: #fff;
    `} // external link icon
    svg {
      float: right;
      margin-right: 1rem;
      margin-left:5px;
    }
  }
`;

const LoadableComponent = Loadable({
  loader: () => import('./search/index'),
  loading: LoadingProvider,
});



const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
`;

const StyledBgDiv = styled('div')`
 padding: 5px;
 background-color: ${({ theme }) => theme.colors.darkGreen};

  @media (max-width: 767px) {
    display: block;
    height: auto;
    padding: 15px;
  }
`;

const Label = styled('div')`
  white-space: nowrap;
  font-size: 20px;
  color: white;
  width:340px;
  padding:11px 0 0 10px;
  @media (max-width: 767px) {
    padding:0; 
    width:100%;
}
`;

const SearchClose = styled.div`
  display:none;
  @media (max-width:767px) {
    display:block;
    text-align:right;
    padding:7px 25px 0 0;
    font-size:16px;
    svg {
      width:24px;
      path {
        fill:${({ theme }) => theme.colors.darkGreen};
      }      
    }
  }
`
 

const SearchOuter = styled('div')`
display:flex;
padding-right:110px;
@media only screen and (max-width: 767px) {
  padding-right:0;
  flex-direction:column;
}
`

const TopNav = styled('div')`
  display:flex;
 
`

const ToggleButton = styled('div')` 
color: ${({ theme }) => theme.colors.darkGreen};
text-align:center;
svg {
  height:40px;
}
`

const SearchWrapper = styled('div')`
  flex:1;
  position:relative;
  @media only screen and (max-width: 767px) {
    position:inherit;
    form {
      margin:0;
    }
  }
`
const SideBarTitle = styled('div')` 
color: ${({ theme }) => theme.colors.darkGreen};
svg {
  path {
    fill :  ${({ theme }) => theme.colors.darkGreen}
  }
}
padding: 34px 0 7px 49px
`;
const StyledLogoContainer = styled.div`
  width:420px; 

  @media (max-width: 1024px) {
    width: 170px;
    min-width: 170px;
    max-width: 170px;
  }

  @media (max-width: 900px) {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
  }

  @media (max-width: 767px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }  
`;

const DeveloperVersion = styled('div')` 
color: ${({ theme }) => theme.leftSideBar.catTitle};;
position:absolute;
left:0;
bottom:15px;
font-size:15px;
font-weight:700;
width:296px;
text-align:center
`;

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width:100%; 

  .mobile_nav {
    position:fixed;
    top:0;
    left:0;
    right:20%;
    max-width:320px;
    bottom:0; 
    width:100%;
    overflow-y:scroll;
    z-index:99;
    background-color: ${({ theme }) => theme.colors.leftSection};
    border-right: ${({ theme }) => theme.colors.darkGreen} 1px solid;
    ul {
      li{width:100%}
    }
    .firstLevel > ul{border-top:${({ theme }) => theme.section.navBorder} 1px solid}
    .firstLevel > ul > li{border-bottom:${({ theme }) => theme.section.navBorder} 1px solid}
    .firstLevel > ul > li > a{font-weight:600; font-size:16px; padding:8px 8px 8px 50px;display:block; position:relative;}
    .firstLevel > ul > li > a > button{ position:absolute; left:16px; top:11px; background:none; border:none;  margin-right:10px}
    .firstLevel > ul > li > a > button svg{width:16px; height:16px}
    .firstLevel > ul > li > a > button svg path{fill:${({ theme }) => theme.leftSideBar.iconNormal};}
    .firstLevel > ul > li > a:hover > button svg path{fill:${({ theme }) => theme.leftSideBar.iconHover};}
    .sideBarUL > li > ul > li.selected{
      background-color: ${({ theme }) => theme.colors.lightGreen};
      border-top: ${({ theme }) => theme.section.navActive} 1px solid;
      border-bottom: ${({ theme }) => theme.section.navActive} 1px solid;
    }
    .firstLevel > ul > li > ul > li > a{display:block}
    .firstLevel > ul > li.active > a,   .firstLevel > ul > li > ul > li.active{
      background-color: ${({ theme }) => theme.leftSideBar.menuActive}; display:block
    }
    .firstLevel > ul > li > a:hover,   .firstLevel > ul > li > ul > li:hover{
      background-color: ${({ theme }) => theme.leftSideBar.menuHover};  
    }
    .firstLevel > ul > li.active > a, .firstLevel > ul > li > ul > li.active > a{
      color: #001933;
    }
    .firstLevel > ul > li > ul{padding-left:50px}
    .firstLevel > ul > li > ul > li{list-style-type:none; border-bottom: 1px solid ${({ theme }) => theme.section.navChildBorder}; padding:8px}
  
  
  
    .sideBarUL > li > ul > li > a {
      color: ${({ theme }) => theme.section.navParentCol};
    }
    .sideBarUL > li > ul > li > ul > li > a {
      color: ${({ theme }) => theme.section.navParentCol};
    }
  }

  @media (max-width: 900px) {
    margin-left: -5px;
  }

  @media (max-width: 767px) {
    display: block;
    margin-left: 0;
    width: 100%;
    .topnav > .navBarUL{display:none}
  }
`;

const Header = ({ location, isDarkThemeActive, toggleActiveTheme, theme }) => {
  const [hidden, setHidden] = useState(true)
  const [menu, setMenu] = useState(false)

  const handleMenu = () => {
    setMenu(false)
    myFunction()
  }

  function myFunction() {
    var x = document.getElementById('navbar');
  
    if (x.className === 'topnav') {
      x.className += ' responsive';
      setMenu(true)
    } else {
      x.className = 'topnav';
      setMenu(false)
    }
  }

 return (<StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            githubUrl
            helpUrl
            tweetText
            logo {
              link
              image
            }
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={data => {


      const twitter = require('./images/twitter.svg');

      const {
        site: {
          siteMetadata: { helpUrl, tweetText, headerLinks },
        },
      } = data;

      
 
      return (
        <div className={'navBarWrapper'}>
          <HeaderWrapper className={'navBarDefault'} theme={theme}>
            <StyledLogoContainer>
              <div className={'navBarHeader'}>
                <Link to={'/'} className={'navBarBrand'}>
                  <img
                    className={'img-responsive displayInline'}
                    src={isDarkThemeActive ? logoImgBw : logoImg}
                    alt={'logo'}
                  />
                </Link>
                <TopNav>
                <SearchClose onClick={()=>setHidden(!hidden)} theme={theme}><Search></Search></SearchClose>
                <ToggleButton
                  theme={theme}
                  onClick={myFunction}
                  className={'navBarToggle'}
                  onKeyDown={myFunction}
                  role="button"
                  tabIndex={0}
                >
                  {!menu?<Menu></Menu>:<Close></Close>}
                </ToggleButton>
                </TopNav>
              </div>
            </StyledLogoContainer>

            <StyledMenuContainer theme={theme}>

             
              {config.header.social ? (
                <ul
                  className="socialWrapper visibleMobileView"
                  dangerouslySetInnerHTML={{ __html: config.header.social }}
                ></ul>
              ) : null}
              <div id="navbar" className={'topnav'}>
                <div className={'visibleMobile mobile_nav'}>
                <DarkModeSwitch
                      isDarkThemeActive={isDarkThemeActive}
                      toggleActiveTheme={toggleActiveTheme}
                      theme={theme}
                    />
                  <SideBarTitle theme={theme}>Categories</SideBarTitle>
                  <Sidebar location={location} setMenu={handleMenu} />
                  <ul>
                  {config.sidebar.links.map((link, key) => {
                    if (link.link !== '' && link.text !== '') {
                      return (
                        <ListItem key={key} to={link.link} theme={theme}>
                          {link.text}
                          <ExternalLink size={16} />
                        </ListItem>
                      );
                    }
                    return false;
                  })}
                  </ul>
                  <DeveloperVersion theme={theme}>developer documentation v{PackagesJson.version}</DeveloperVersion>
                </div>
                <ul className={'navBarUL navBarNav navBarULRight'}>
                  {headerLinks.map((link, key) => {
                    if (link.link !== '' && link.text !== '') {
                      return (
                        <li key={key}>
                          <a
                            aria-label="Sidebar Link"
                            className="sidebarLink"
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            dangerouslySetInnerHTML={{ __html: link.text }}
                          />
                        </li>
                      );
                    }
                    return false;
                  })}
                  {helpUrl !== '' ? (
                    <li>
                      <a href={helpUrl}>
                        <img src={help} alt={'Help icon'} />
                      </a>
                    </li>
                  ) : null}
                  

                  {config.sidebar.links.map((link, key) => {
                    if (link.link !== '' && link.text !== '') {
                      return (
                        <ListItem key={key} to={link.link} theme={theme}>
                          {link.text}
                          <ExternalLink size={16} />
                        </ListItem>
                      );
                    }
                    return false;
                  })}

                  {tweetText !== '' ? (
                    <li>
                      <a
                        href={'https://twitter.com/intent/tweet?&text=' + tweetText}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img className={'shareIcon'} src={twitter} alt={'Twitter'} />
                      </a>
                    </li>
                  ) : null}

                  {config.header.social ? (
                    <li className={'hiddenMobile'}>
                      <ul
                        className="socialWrapper"
                        dangerouslySetInnerHTML={{ __html: config.header.social }}
                      ></ul>
                    </li>
                  ) : null}
                  {/* {githubUrl !== '' ? (
                    <li className={'githubBtn'}>
                      <GitHubButton
                        href={githubUrl}
                        data-show-count="true"
                        aria-label="Star on GitHub"
                      >
                        Star
                      </GitHubButton>
                    </li>
                  ) : null} */}
                  <li>
                    <DarkModeSwitch
                      isDarkThemeActive={isDarkThemeActive}
                      toggleActiveTheme={toggleActiveTheme}
                      theme={theme}
                    />
                  </li>
                </ul>
              </div>
            </StyledMenuContainer>

            <div style={{ clear: "both" }} />
          </HeaderWrapper>

          <StyledBgDiv isDarkThemeActive={isDarkThemeActive} theme={theme}>
            {isSearchEnabled ? (
              <SearchOuter>
                <Label>Developer Documentation</Label>
                <SearchWrapper>
                  <LoadableComponent collapse={true} indices={searchIndices} theme={theme} hidden={hidden} setHidden={setHidden} />
                </SearchWrapper>
              </SearchOuter>
            ) : null}
          </StyledBgDiv>
        </div>
      );
    }}
  />
)
};

export default Header;
